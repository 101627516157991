import React, { useMemo } from 'react'
import type { PageProps } from 'gatsby'
import { NpmPage500 } from '@plurix/ecom-pages'

type Props = PageProps

const useErrorState = (location: Location) =>
  useMemo(() => {
    const params = new URLSearchParams(location.search)
    const errorId = params.get('errorId')
    const fromUrl = params.get('from')

    return {
      errorId,
      fromUrl,
    }
  }, [location.search])

function Page({ location }: Props) {
  const { errorId, fromUrl } = useErrorState(location)

  return <NpmPage500 errorId={errorId} fromUrl={fromUrl} />
}

export default Page
